<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-title>Report Incident</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">
            <ion-header collapse="condense">
                <ion-toolbar>
                    <ion-title size="large">Report Incident</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-item v-if="loading">
                <ion-label>Loading</ion-label>
                <ion-spinner color="primary"></ion-spinner>
            </ion-item>
            <template v-else>
                <ion-item>
                    <ion-label :color="incident.occurred_at ? 'none' : 'danger'">Date Occurred:</ion-label>
                    <ion-datetime display-format="MMMM DD, YYYY HH:mm" v-model="incident.occurred_at" required></ion-datetime>
                </ion-item>
                <ion-item>
                    <ion-label :color="incident.location ? 'none' : 'danger'" position="floating">Location Of Incident:</ion-label>
                    <ion-textarea v-model="incident.location" autoGrow required></ion-textarea>
                </ion-item>
                <ion-item>
                    <ion-label :color="incident.persons ? 'none' : 'danger'" position="floating" class="ion-text-wrap">Person(s) Involved / Witnesses:</ion-label>
                    <ion-textarea v-model="incident.persons" autoGrow required></ion-textarea>
                </ion-item>
                <ion-list>
                    <ion-radio-group v-model="incident.is_accident">
                        <ion-list-header>
                            <ion-label style="font-weight: bold;">Incident Type</ion-label>
                        </ion-list-header>

                        <ion-item>
                            <ion-label class="ion-text-wrap">Accident/Injury/Motor Vehicle/Mobile Equipment</ion-label>
                            <ion-radio slot="start" :value="!!1"></ion-radio>
                        </ion-item>

                        <ion-item>
                            <ion-label>Near Miss</ion-label>
                            <ion-radio slot="start" :value="!!0"></ion-radio>
                        </ion-item>
                    </ion-radio-group>
                </ion-list>
                <ion-list>
                    <ion-list-header>
                        <ion-label style="font-weight: bold;" class="ion-text-wrap">Incident Category (Select All That Are Applicable):</ion-label>
                    </ion-list-header>
                    <ion-item>
                        <ion-label class="ion-text-wrap">Motor Vehicle Accident (Remember To File A Police Report And Obtain Report Number)</ion-label>
                        <ion-checkbox slot="end" value="vehicle" :checked="incident.entities.includes('vehicle')" @ionChange="handleEntityChange">
                        </ion-checkbox>
                    </ion-item>
                    <ion-item>
                        <ion-label>Mobile Equipment</ion-label>
                        <ion-checkbox slot="end" value="equipment" :checked="incident.entities.includes('equipment')" @ionChange="handleEntityChange">
                        </ion-checkbox>
                    </ion-item>
                    <ion-item>
                        <ion-label>Property Or Non-Mobile Equipment</ion-label>
                        <ion-checkbox slot="end" value="property" :checked="incident.entities.includes('property')" @ionChange="handleEntityChange">
                        </ion-checkbox>
                    </ion-item>
                    <ion-item>
                        <ion-label>Injury – Body Part</ion-label>
                        <ion-select :value="incident.injuries" multiple @ionChange="handleInjuryChange">
                            <ion-select-option value="head">Head</ion-select-option>
                            <ion-select-option value="eye">Eye</ion-select-option>
                            <ion-select-option value="face">Face</ion-select-option>
                            <ion-select-option value="shoulder">Shoulder</ion-select-option>
                            <ion-select-option value="back">Back</ion-select-option>
                            <ion-select-option value="torso">Torso</ion-select-option>
                            <ion-select-option value="arm">Arm</ion-select-option>
                            <ion-select-option value="hand">Hand</ion-select-option>
                            <ion-select-option value="leg">Leg</ion-select-option>
                            <ion-select-option value="ankle">Ankle</ion-select-option>
                            <ion-select-option value="foot">Foot</ion-select-option>
                        </ion-select>
                    </ion-item>
                </ion-list>
                <ion-item>
                    <ion-label :color="incident.report ? 'none' : 'danger'" position="floating" class="ion-text-wrap" required>Incident Description:</ion-label>
                    <ion-textarea v-model="incident.report" autoGrow required></ion-textarea>
                </ion-item>
                <ion-item>
                    <ion-label :color="!incident.is_accident || !incident.injuries.length || incident.injury_description ? 'none' : 'danger'"
                               position="floating" class="ion-text-wrap">Injury Description:</ion-label>
                    <ion-textarea v-model="incident.injury_description" autoGrow :required="incident.is_accident && incident.injuries.length"></ion-textarea>
                </ion-item>
                <ion-item>
                    <ion-label :color="!incident.is_accident || !incident.injuries.length || incident.medical_facility ? 'none' : 'danger'"
                               position="floating" class="ion-text-wrap">Medical Facility Used:</ion-label>
                    <ion-textarea v-model="incident.medical_facility" autoGrow :required="incident.is_accident && incident.injuries.length"></ion-textarea>
                </ion-item>
                <ion-item>
                    <ion-label style="font-weight: bold;" color="dark">Photos (Please Take Pictures If Possible):</ion-label>
                </ion-item>
                <ion-button color="dark" @click="takePhoto()" expand="block">
                    <ion-icon :icon="camera"></ion-icon>
                    &nbsp;Take Photo
                </ion-button>
                <ion-grid>
                    <ion-row>
                        <ion-col size="6" :key="photo" v-for="photo in photos">
                            <ion-img
                                    :src="photo.webviewPath"
                                    @click="showActionSheet(photo)"
                            ></ion-img>
                        </ion-col>
                    </ion-row>
                </ion-grid>
                <hr/>
                <ion-button @click="presentConfirm" :disabled="!isValid || submitting" color="primary" expand="block">
                    Submit Incident Report
                </ion-button>
            </template>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonItem, IonRadioGroup, IonRadio, IonSpinner, IonButton,
    IonLabel, IonList, IonListHeader, IonTextarea, IonDatetime, IonCheckbox, IonGrid, IonRow, IonCol, IonImg,
    IonIcon, IonSelect, IonSelectOption, actionSheetController, alertController, toastController } from '@ionic/vue';
import Api from '@/services/Api';
import { Geolocation } from '@ionic-native/geolocation';
import { usePhotoGallery } from '@/composables/usePhotoGallery';
import { camera, trash, close } from 'ionicons/icons';

export default {
    name: 'ReportIncident',
    components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonItem, IonRadioGroup, IonRadio, IonSpinner,
        IonLabel, IonButton, IonList, IonListHeader, IonTextarea, IonDatetime, IonCheckbox, IonGrid, IonRow,
        IonCol, IonImg, IonIcon, IonSelect, IonSelectOption },
    setup() {
        const { photos, takePhoto, deletePhoto, clearPhotos } = usePhotoGallery();
        const showActionSheet = async (photo) => {
            const actionSheet = await actionSheetController.create({
                header: 'Photos',
                buttons: [
                    {
                        text: 'Delete',
                        role: 'destructive',
                        icon: trash,
                        handler: () => {
                            deletePhoto(photo);
                        },
                    },
                    {
                        text: 'Cancel',
                        icon: close,
                        role: 'cancel',
                        handler: () => {
                            // Nothing to do, action sheet is automatically closed
                        },
                    },
                ],
            });
            await actionSheet.present();
        };

        return {
            photos,
            takePhoto,
            showActionSheet,
            clearPhotos,
            camera,
            trash,
            close,
        };
    },
    data(){
        return {
            loading: true,
            submitting: false,
            error: null,
            incident: {
                /* eslint-disable */
                is_accident: true,
                occurred_at: null,
                location: null,
                latitude: null,
                longitude: null,
                report: null,
                persons: null,
                entities: [],
                injuries: [],
                injury_description: null,
                medical_facility: null,
                attachments: [],
                /* eslint-enable */
            },
        };
    },
    async mounted(){
        await this.clearPhotos();
        try {
            const { coords } = await Geolocation.getCurrentPosition({
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            });
            this.incident.latitude = coords.latitude;
            this.incident.longitude = coords.longitude;
        } catch(e){
            console.log('GPS not enabled.');
        } finally {
            this.loading = false;
        }
    },
    computed: {
        isValid(){
            if(!this.incident.occurred_at || !this.incident.location || !this.incident.report || !this.incident.persons){
                return false;
            }
            if(this.incident.is_accident && this.incident.injuries.length && (!this.incident.injury_description || !this.incident.medical_facility)){
                return false;
            }
            return true;
        },
    },
    methods: {
        async presentConfirm(){
            await alertController
                .create({
                    header: 'Confirm Submission?',
                    message: 'Are you sure you wish to submit this incident report?' +
                        '<br/><br/><strong>It cannot be edited once it has been submitted!</strong>',
                    buttons: [
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            color: 'secondary',
                            handler: (alert) => {
                                alert.dismiss();
                            },
                        },
                        {
                            text: 'Confirm',
                            color: 'primary',
                            handler: () => {
                                this.submit();
                            },
                        },
                    ],
                })
                .then(alert => alert.present())
        },
        async showSuccessfulSubmissionToast() {
            const toast = await toastController
                .create({
                    message: 'Incident was reported successfully!',
                    duration: 2000,
                    color: 'success',
                });
            return toast.present();
        },
        async showSubmissionErrorToast(message) {
            const toast = await toastController
                .create({
                    message,
                    duration: 2000,
                    color: 'warning',
                });
            return toast.present();
        },
        handleEntityChange($event){
            if(!this.incident.entities.includes($event.detail.value)){
                this.incident.entities.push($event.detail.value);
            } else {
                this.incident.entities.splice(this.incident.entities.indexOf($event.detail.value), 1);
            }
        },
        handleInjuryChange($event){
            this.incident.injuries = $event.detail.value;
        },
        async submit(){
            this.submitting = true;
            this.photos.forEach((photo) => {
                this.incident.attachments.push(photo.b64Data);
            });
            const response = await Api.incidents.submit(this.incident);
            await this.clearPhotos();
            if(response.success){
                await this.showSuccessfulSubmissionToast();
            } else {
                await this.showSubmissionErrorToast(response.error);
            }
            this.submitting = false;
            await this.$router.push({ name: 'incidents'});
        },
    },
};
</script>
